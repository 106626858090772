export default function (Alpine, intlTelInput) {
  Alpine.data(
    "phoneNumber",
    ({
      isValid = true,
      initialError = null,
      initialCountry = "",
      autofocus = true,
      fullscreen = false,
    }) => ({
      intlTelInput: null,
      isValid,
      formValidity: null,
      async init() {
        const formValidityWrapper = this.$root.closest(
          '[x-data="formValidity"]',
        );
        this.formValidity = formValidityWrapper
          ? Alpine.$data(formValidityWrapper)
          : false;
        this.isValid = isValid;
        if (this.formValidity) {
          this.formValidity.messages[this.$refs.input.name] = initialError;
        }
        this.$refs.input.blurred = Boolean(initialError);
        this.intlTelInput = intlTelInput(this.$refs.input, {
          customContainer: "w-full",
          preferredCountries: ["fr", "de", "be", "nl", "es"],
          initialCountry,
          autoPlaceholder: "aggressive",
          dropdownContainer: this.$el,
          useFullscreenPopup: fullscreen,
        });

        this.errorsMap = [
          window.gettext("Please fill in a valid phone number."),
          window.gettext("Country code is invalid."),
          window.gettext("The number is too short."),
          window.gettext("The number is too long."),
        ];

        if (autofocus) {
          await this.$nextTick(() => this.$refs.input.focus());
        }
      },
      inputEvents: {
        /* we stop propagation to avoid formValidity exec */
        "@input"(ev) {
          this.handleChange(ev);
        },
        /* we stop propagation to avoid formValidity exec */
        "@focusout"(ev) {
          this.handleChange(ev);
        },
        "@countrychange"(ev) {
          this.handleChange(ev);
        },
        "@submit.window"(ev) {
          this.handleChange(ev);
        },
      },
      normalizedErrors() {
        const errorCode = this.intlTelInput.getValidationError();
        return errorCode === -99 ? 0 : errorCode;
      },
      handleChange(e) {
        const {
          $refs: { input },
          intlTelInput,
        } = this;

        const isValid = intlTelInput.isValidNumberPrecise();

        if (e.type === "focusout" || isValid) {
          input.blurred = true;
          input.value = intlTelInput.getNumber(1);
        }

        if (!isValid && ["input", "focusout"].includes(e.type)) {
          e.stopPropagation();
        }
        if (input.blurred) {
          this.isValid = isValid;

          if (this.formValidity) {
            if (!this.isValid) {
              this.formValidity.messages[input.name] =
                this.errorsMap[this.normalizedErrors()];
            } else {
              this.formValidity.messages[input.name] = "";
            }
          }
          input.classList.toggle("input--text-error", !this.isValid);
        }
      },
    }),
  );
}
